&.submission-modal {
  //from-payments-table
  &.from-payments-table {
    .input-group {
      &.f-price {
        .input-group-text {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right-width: 0;
          border-color: rgba(0, 0, 0, 0.26);
        }
        .form-control {
          min-height: 52px;
          border-color: rgba(0, 0, 0, 0.26);
          &:disabled {
            background-color: white;
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
    }
  }
}
