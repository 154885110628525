// variables
@import '../../../../variables/variables';
$login-v2-aside-width : calc(100%/3);

// mixins
@mixin selected() {

    //border: 3px dashed rgb(204, 204, 204);
    &::before {
        position: absolute;
        font-size: 19.5px;
        color: #333333;
        top: -9px;
        right: -10px;
        z-index: 1;
    }

    &::after {
        position: absolute;
        top: -16px;
        right: -16px;
        background-color: rgb(204, 204, 204);
        content: "";
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
}

// scss
&.v2 {

    color: #333333;

    @include media-breakpoint-lg {
        padding-left: $login-v2-aside-width;
    }

    .kt-switch {
        label {
            position: relative;
            z-index: 0;
            top: 2px;

            input {
                &:checked {
                    &~span {
                        &::after {
                            border: 0;
                            background-color: $lyttleInk-green;
                        }
                    }
                }
            }
        }
    }

    // login-aside
    .login-aside {

        color: var(--pkg_primary_color);
        background-color: var(--login_aside_bg_color);

        @include media-breakpoint-lg-down {
            position: relative;
            z-index: 0;
        }

        @include media-breakpoint-lg {
            max-width: $login-v2-aside-width;
        }

        // dark overlay
        &.dark-overlay {
            &::before {

                background-color: rgba(0, 0, 0, 0.5);
                content: " ";
                z-index: -1 !important;

                @include media-breakpoint-lg-down {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                @include media-breakpoint-lg {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    height: 100vh;
                    width: 100%;
                    max-width: $login-v2-aside-width;
                }

            }
        }

        .branding-logo{
            max-width: 100%;
            max-height: 100px;
        }

        .aside-progress-bar {
            //justify-content: center;
            align-items: center;
            display: flex;
            //flex: 1 1 auto;
            flex-direction: column;
            // max-width: 80%;
            // min-width: 80%;
            width: 100%;
            margin: 1px auto 79px auto;

            .progress {
                height: 32px;
                border-radius: 16px;
                width: 100%;
                margin-bottom: 5px;

                .progress-bar {
                    background-color: $lyttleInk-green;
                }
            }
        }

        .aside-points {
            width: 100%;
            margin: -6px auto 74px auto;
            text-align: center;

            ul {
                text-align: left;
                margin-top: 20px;

                li {
                    position: relative;
                    z-index: 0;
                    padding: 0 0 5px 38px;
                    font-size: 16px;
                    font-weight: 600;

                    .symbol {
                        position: absolute;
                        top: 2px;
                        left: 0;
                    }
                }
            }
        }

        .btm-link {
            margin-top: auto;
            text-align: center;
            padding: 16px 0;
            font-size: 14px;
            font-weight: 400;

            a {
                font-weight: 600;
                text-decoration: underline;
                color: inherit;

                &:hover {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }

    }

    // login-content
    .login-content {
        padding: 24px;

        @include media-breakpoint-md {
            padding: 32px;
        }

        @include media-breakpoint-lg {
            padding: 64px;
            max-width: none;
        }

        .login-form {
            

            .form-title {
                color: var(--pkg_title_color);
            }

            // form-group
            .form-group {
                margin-bottom: 32px;

                label {
                    font-size: 16px;
                    color: #181C32;
                    margin-bottom: 16px;

                    span {
                        display: block;

                        &.title {
                            font-weight: 600;
                        }

                        &.sub-title {
                            color: #B5B5C3;
                            font-weight: 500;
                            font-size: 14px;
                        }
                    }
                }

                .color-picker {
                    margin-bottom: 0 !important;
                }

                // choose
                .choose {
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-auto-flow: row;
                    grid-gap: 16px;

                    @include media-breakpoint-md {
                        display: inline-grid;
                        grid-auto-flow: column;
                    }

                    .btn {
                        min-height: 42px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;

                        @include media-breakpoint-md {
                            width: 200px;
                        }

                        position: relative;
                        z-index: 0;
                        padding: 8px 32px;
                        border-radius: 0px !important;

                        &.selected {
                            @include selected;
                        }
                    }

                    &.button-style {
                        .btn {
                            border-color: #333333;
                            border-width: 2px;
                            background-color: #fff;

                            &.rounded {
                                border-radius: 8px !important;
                            }

                            &.circle {
                                border-radius: 50px !important;
                            }

                            &:hover {
                                background-color: #333333;
                                color: white;
                            }

                        }
                    }

                    &.main-font {
                        .btn {
                            //border: 2px solid #171717;
                            background-color: #f4f0ec;
                            color: #171717;
                            font-weight: 600;
                            padding: 0;
                            border: 0;
                            border-radius: 6px !important;
                            font-size: 22px;
                            line-height: 1.25;

                            &.selected,
                            &:hover {
                                background-color: #222;
                                color: #fff;
                            }

                            &.montserrat {
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 900;
                                font-size: 20px;
                            }

                            &.sacramento {
                                font-family: 'Sacramento', cursive;
                                font-size: 24px;

                                span {
                                    margin-top: 4px;
                                }
                            }

                            &.roboto-mono {
                                font-family: 'Roboto Mono', monospace;
                            }

                            img {
                                opacity: 0;
                            }

                            span {
                                position: absolute;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                padding: 16px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                    }

                    .graphic {
                        @include media-breakpoint-md {
                            width: 200px;
                        }

                        position: relative;
                        z-index: 0;
                        cursor: pointer;

                        &:hover {
                            .image {
                                position: relative;
                                z-index: 0;

                                &::before {
                                    position: absolute;
                                    z-index: 1;
                                    background-color: rgba($color: #000000, $alpha: 0.1);
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    content: "";
                                    border-radius: 6px;
                                }
                            }
                        }

                        .text {
                            margin-top: 5px;
                            color: #181C32;
                            font-weight: 500;
                            text-align: center;
                        }

                        &.selected {
                            @include selected;
                        }
                    }

                }

                // choose-image
                .choose-image {
                    overflow: hidden;
                    max-width: 450px;
                    height: 300px;
                    border-radius: 0.42rem;
                }

                //.kt-checkbox
                .kt-checkbox>span {
                    border-width: 2px;
                }

                .msg {
                    display: none;
                }

                &.error {
                    .title {
                        display: flex;
                    }

                    .msg {
                        display: block;
                        color: #fd397a;
                        font-weight: 500;
                        position: relative;
                        z-index: 0;
                        left: 4px;
                        padding: 0 0 5px 28px;

                        .icon {
                            position: absolute;
                            top: 3px;
                            left: 4px;
                            font-size: 20px;
                        }
                    }
                }

                .btn-primary {
                    border-color: var(--pkg_cta_background_color);
                    background-color: var(--pkg_cta_background_color);
                    color: var(--pkg_cta_text_color);
                }

            }

            //choose-multiple-options
            .choose-multiple-options {
                padding: 32px 0 0 0;

                .option {
                    label {
                        margin-bottom: 0;
                    }

                    .row {
                        align-items: center;
                    }

                    .kt-switch {
                        
                    }
                }
            }

            //card-entry-form
            .card-entry-form{
                .StripeElement{
                    padding-top: 27px !important;
                }
            }


        }

    }

    &:not(.v1) {

        // login-content
        .login-content {

            .login-form {
                max-width: none;
            }

        }
    }

   @import '../../partnership/partnership';

}