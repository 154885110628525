// variables
$disable-view-green : rgb(11, 211, 119);

// mixins
@keyframes disable-view-stroke {
  100% {
    stroke-dashoffset: 0
  }
}

@keyframes disable-view-scale {

  0%,
  100% {
    transform: none
  }

  50% {
    transform: scale3d(1.1, 1.1, 1)
  }
}

@keyframes disable-view-fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $disable-view-green
  }
}

/* The typing effect */
@keyframes disable-view-typing {
  from {
    width: 0
  }

  to {
    width: calc(100% - 26px)
  }
}

@keyframes disable-view-typing-dots {
  from {
    width: 0
  }

  to {
    width: 26px;
  }
}

// SCSS
.disable-view {

  .spinner {
    margin-top: -60px;
  }

  .logo-svg {
    top: 0;
  }

  .loading-text {

    .points {

      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-flex;
      flex-direction: column;
      font-size: 22px;
      min-width: 325px;

      li {
        opacity: 0;
        position: relative;
        z-index: 0;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: left;
        text-transform: capitalize;
        margin-bottom: 10px;

        svg {
          max-width: 36px;

          &.checkmark {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            display: block;
            stroke-width: 2;
            stroke: #fff;
            stroke-miterlimit: 10;

            margin: 0 12px 0 0;
            box-shadow: inset 0px 0px 0px $disable-view-green;

            .checkmark__circle {
              stroke-dasharray: 166;
              stroke-dashoffset: 166;
              stroke-width: 2;
              stroke-miterlimit: 10;
              stroke: $disable-view-green;
              fill: none;
            }

            .checkmark__check {
              transform-origin: 50% 50%;
              stroke-dasharray: 48;
              stroke-dashoffset: 48;
            }
          }

        }

        &.b-text {
          margin-top: 36px !important;
          font-size: 28px;
          line-height: 1;
          bottom: -30px;
          color: $disable-view-green;
        }

        span {
          font-style: normal;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
        }

        &.active {
          opacity: 1;
          display: flex;

          svg {
            &.checkmark {
              animation: disable-view-fill .4s ease-in-out .4s forwards, disable-view-scale .3s ease-in-out .9s both;

              .checkmark__circle {
                animation: disable-view-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
              }

              .checkmark__check {
                animation: disable-view-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
              }
            }
          }

          span {
            animation:
              disable-view-typing 1.0s steps(40, end)
          }

          &.b-text {
            bottom: 0;
            span{
              animation:
              disable-view-typing-dots 1.0s steps(4, end)
            }
          }
        }

      }
    }

  }

}