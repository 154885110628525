/* Custom Scripts Below */

.visible-hidden {
  visibility: hidden;
}
.fc-1 {
  color: #646c9a !important;
}
body {
  background: #eef0f8;
}
.fs-16 {
  font-size: 16px !important;
}
.lh-1 {
  line-height: 1;
}
.hover-w-7:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Margin & Padding */
.side-margin-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.kt-aside--minimize .side-margin-20 {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-l-50 {
  margin-left: 50px !important;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-t-0 {
  padding-top: 0px;
}
.mw-100 {
  min-width: 100px;
}
.mw-120 {
  min-width: 120px;
}
.pb-32 {
  padding-bottom: 32px;
}
.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.pr-35 {
  padding-right: 35px;
}
.px-2 {
  padding-right: 2px;
  padding-left: 2px;
}

/* fill color */
.fill-white {
  fill: white;
}

/* Stat Boxes */
.kt-widget24 {
  text-align: center;
  margin: 30px 0px;
}
.kt-widget24__info {
  width: 100%;
}
.kt-widget24__title {
  display: block !important;
}
.kt-widget24 .kt-widget24__details .kt-widget24__stats {
  font-size: 40px;
}

/* Add Page Card */
.add-page-portlet {
  box-shadow: none;
  border: 10px dashed #ddd;
  background: none;
}
.add-page-portlet a.page-name {
  color: #bbb !important;
}
.add-page-two {
  box-shadow: none;
  border: none;
  background: #d8dbe8;
}
.add-page-two a.page-name {
  color: #fff !important;
}
@media (min-width: 1200px) {
  .add-page-portlet .kt-widget__head,
  .add-page-two .kt-widget__head {
    margin-top: 50px !important;
  }
}

/* Page Card */
.kt-widget__stats {
  padding: 1.7rem 1.5rem 0;
  font-size: 13px;
}
.kt-widget__stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.kt-widget__stat .kt-widget__label {
  color: #48465b;
  font-weight: 600;
}
.kt-widget__stat .kt-widget__data {
  color: #74788d;
  font-weight: 400;
}

/* Inputs */
.keywords-lg {
  padding: 0.65rem 1.65rem;
  font-size: 1.25rem;
}
.kt-widget.kt-widget--user-profile-4
  .kt-widget__head
  .kt-widget__content
  .kt-widget__section
  .page-name {
  font-size: 1.9rem;
}
.kt-badge.kt-badge--warning {
  color: #fff;
}

/* Start TABLE Styles */
.ReactTable {
  border: unset !important;
}

.Table__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-start;
  padding: 20px 10px;
}

.Table__page {
  display: flex;
  justify-content: flex-end;
  padding: 25px 15px;
}

.Table__visiblePagesWrapper {
  margin-left: 10px;
  margin-right: 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 29px;
  width: 29px;
  border-radius: 5px;
  background-color: transparent;
  color: #93a2dd;
  cursor: pointer;
}

.Table__pageButton--active,
.Table__pageButton:hover {
  color: #fff;
  font-weight: bold;
  -webkit-transition: background-color 3.3s ease;
  background-color: #074785;
}

.table-status {
  border: 1px solid #e2e5ec !important;
  height: 38px !important;
}

.ReactTable .rt-table {
  overflow: unset;
  overflow-y: visible !important;
}

.ReactTable .rt-tbody {
  overflow: visible !important;
}

.switchPageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 29px;
  width: 29px;
  border-radius: 5px;
  background-color: #f0f3ff;
  color: #93a2dd;
  cursor: pointer;
}

.switchPageButton:disabled {
  cursor: not-allowed;
  color: #e0e5f5;
  background-color: #fbfcff;
}

.switchPageButton:disabled:hover {
  cursor: not-allowed;
  color: #f1f4fc;
  font-weight: normal;
  background-color: #fbfcff;
}

.switchPageButton:active,
.switchPageButton:hover {
  color: #fff;
  background-color: #074785;
}

.select-page-text {
  color: #05223d;
  font-size: 13px;
  margin-left: 15px;
}

.select-page {
  border-width: 0;
  color: #93a2dd;
  font-size: 13px;
  font-weight: 600;
  height: 29px;
  padding-left: 10px;
  width: 60px;
  border-radius: 5px;
  background: url(../../assets/media/icons/chevron-down.svg) no-repeat right
    #f0f3ff;
  -webkit-appearance: none;
}

.select-page option {
  font-size: 13px;
  font-weight: bold;
}

.select-page:hover {
  border-width: 0;
  color: #fff;
  background-color: #074785;
}

.select-page:active {
  border-width: 0;
  color: #fff;
  background-color: #074785;
  -webkit-appearance: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #333333;
}

.rt-td {
  color: #6c7293;
  padding: 0;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::before {
  content: "\2193";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}
.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::after {
  content: "\2191";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}

.-sort-asc .rt-resizer::before {
  content: "\2191";
  color: #595d6e;
}
.-sort-asc .rt-resizer::after {
  content: "\2193";
  color: #595d6e;
  opacity: 0.3;
}
.-sort-desc .rt-resizer::before {
  content: "\2191";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}
.-sort-desc .rt-resizer::after {
  content: "\2193";
  color: #595d6e;
  vertical-align: "center";
}

.ReactTable .rt-resizer {
  top: 5px;
}

.ReactTable .rt-td {
  align-self: center;
}

.Table__page .select-page:hover {
  background-color: #333333;
}

.rt-resizable-header-content {
  color: #595d6e !important;
  font-weight: 500;
}
/* End TABLE Styles */

.rdw-editor-main {
  padding: 1.15rem 1.65rem;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-color: #e2e5ec;
  border-style: solid;
}

.text-editor-wrapper {
  border: 1px solid #e2e5ec;
}

.rdw-editor-toolbar {
  border-style: none;
}

.select-status-filter {
  width: 300px;
}

.error-message {
  color: #fd397a;
}

.stat_chart canvas {
  height: 100px !important;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eaeaea;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

span.input-group-addon {
  display: inline-flex;
  align-items: center;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group.input-group-solid .input-group-prepend,
.input-group.input-group-solid .input-group-append,
.input-group.input-group-solid .input-group-addon,
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.metr_v702 .rounded-lg.login-slug-field {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group-append-bg-dark span {
  background: #333;
  width: 180px;
  border: 0;
  color: white;
  font-weight: 600;
}

.input-group-append-bg-dark-error span {
  background: #fa6160;
  color: white;
}

.input-group-append-bg-green span {
  background: #37db70;
  color: white;
}

.brand-green {
  background: #37db70;
  color: white;
}

.brand-purple {
  background: #5e39a1;
  color: white;
}

.brand-blue {
  background: #5578eb;
  color: white;
}

.kt-wizard-v2__nav-item {
  cursor: pointer;
}

.modal-50vw {
  width: 50vw;
  max-width: 50vw;
}

.modal-60vw {
  width: 60vw;
  max-width: 60vw;
}

.modal-70vw {
  width: 70vw;
  max-width: 70vw;
  margin: 1.75rem auto;
}
@media (max-width: 576px) {
  .modal-70vw {
    width: 90vw;
    max-width: 90vw;
    margin: 1.75rem auto;
  }
}

.disable-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Poppins, Helvetica, sans-serif;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.disable-view .logo-svg {
  width: 240px;
  height: 240px;
  top: 31px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.disable-view-absolute {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
}

.activate-shop-banner {
  background-color: #333;
  color: white;
  padding: 20px;
}

.google-suggestion-item {
  cursor: pointer;
  padding: 5px;
  padding-left: 8px;
  font-size: 12px;
  margin: 0px;
}

.google-suggestion-item:hover {
  background-color: #f5f5f5;
}

.google-suggestion-list {
  box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc !important;
}

.google-suggestion-item-manual {
  color: #fa6160;
  text-decoration: underline;
}

.product-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

p.shop-charges-disabled {
  color: #fa1f60;
}

p.shop-charges-enabled {
  color: #fa6160;
}

.form-control.form-control-solid,
.text-editor.form-control-solid {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}

.input-group.input-group-solid,
.async-select-paginate__control {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f6f9 !important;
  border-radius: 0.42rem;
}

.async-select-paginate-filter__control {
  border-color: #e2e5ec !important;
}

.async-select-paginate:first-child,
.async-select-paginate > div:first-child {
  background-color: #f3f6f9 !important;
}

.input-group.input-group-solid .input-group-prepend,
.input-group.input-group-solid .input-group-append,
.input-group.input-group-solid .input-group-addon,
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}
.react-tel-input .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem 0.65rem 48px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
}

.chip-container > div,
.chip-container input {
  background-color: #f3f6f9 !important;
  border: none !important;
}

.async-select-paginate div {
  border: 0;
}

.separator.separator-dashed {
  border-bottom: 1px dashed #ebedf3;
}
.separator.separator-custom {
  border-bottom-color: #f3f6f9;
}
.separator.separator-border-2 {
  border-bottom-width: 2px;
}
.separator.separator-border-3 {
  border-bottom-width: 3px;
}
.separator.separator-border-4 {
  border-bottom-width: 4px;
}

.separator {
  height: 0;
  border-bottom: solid;
}

.Collapsible .collapse .svg-icon svg {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.Collapsible .svg-icon svg {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.react-tel-input .flag-dropdown:focus .selected-flag {
  background-color: transparent;
}

.flag-dropdown:hover .selected-flag {
  background-color: #eaeaea !important;
}

.async-select-paginate__control {
  background-color: red;
}

.w-50 {
  width: 50vw;
  max-width: 50vw;
}

.form-control:disabled {
  background-color: #f1f1f1;
  opacity: 1;
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-indeterminate {
  background-color: #c1c1c1 !important;
}
.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-barColorPrimary,
.MuiLinearProgress-bar.MuiLinearProgress-bar2Indeterminate.MuiLinearProgress-barColorPrimary {
  background-color: #333 !important;
}

.btn.btn-light {
  color: #80808f;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #3f494d !important;
  border-color: #3f494d !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-hover-primary:focus:not(.btn-text) i,
.btn.btn-hover-primary.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.Collapsible.open .Collapsible__contentOuter {
  overflow: visible !important;
}

.product-categories {
  padding-top: calc(0.65rem + 1px) !important;
}

.alert.alert-custom.alert-white .alert-text {
  color: #3f4254;
}

.alert.alert-custom.alert-white .alert-icon i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-custom.alert-warning .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-warning .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-dark {
  background-color: #181c32;
  border-color: #181c32;
}

.ki-close:before {
  content: "\f130";
}

.ki:before {
  font-family: "Ki";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

/* starts : custom base table css */
.c-base-table.scroll-fixed {
  padding-top: 88px;
}
.c-base-table.scroll-fixed .action-bar,
.c-base-table.scroll-fixed .rt-thead,
.c-base-table.scroll-fixed::before {
  position: fixed;
  top: 90px;
  background-color: #fff;
  left: 315px;
  right: 45px;
  z-index: 1;
}
.c-base-table.scroll-fixed .rt-thead {
  top: 128px;
}
.c-base-table.scroll-fixed::before {
  content: " ";
  z-index: 2;
  border-top: 25px solid white;
  display: block;
  top: 65px;
}
/* end : custom base table css */

/* starts : custom react-switch css */
.c-switch {
  top: 2.5px;
}
/* end : custom react-switch css */

.modal-70 {
  width: 70% !important;
  max-width: 70% !important;
}

@media (max-width: 576px) {
  .modal-70 {
    width: 90% !important;
    max-width: 90% !important;
    margin: 1.75rem auto;
  }
}

.image-search-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4px;
}
.image-search-thumbnail-text {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 4px;
  font-weight: 200;
  font-size: 12px;
}

/* starts : custom checkbox css */
.kt-checkbox.c-checkbox {
  padding-left: 45px;
  padding-top: 5px;
}
.kt-checkbox.c-checkbox > span {
  height: 28px;
  width: 28px;
  left: 5px;
  border-radius: 0.25rem;
}
.kt-checkbox.c-checkbox > span {
  border-color: #333333;
}
.kt-checkbox.c-checkbox > input:checked ~ span {
  background-color: #333333;
}
.kt-checkbox.c-checkbox > input:checked ~ span:after {
  border-color: #fff;
}
/* end : custom checkbox css */

.cursor-pointer {
  cursor: pointer;
}

.form-control-error {
  border-color: #fa1f60 !important;
}

.choose-image {
  position: relative;
  z-index: 0;
}
.choose-image::after {
  background-image: url(../media/bg/step-02.png);
  content: " ";
  z-index: 1;
}
.choose-image .bg-text {
  background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.choose-image::after,
.choose-image .uploaded-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.choose-image .uploaded-image {
  z-index: 3;
}
.choose-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

/* starts, login-1 page fixed login-aside */
.login-1 {
}
@media (min-width: 992px) {
  .login-1 .login-aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1025px) {
  .login-1 {
    padding-left: 600px;
  }
}
@media (max-width: 1399.98px) and (min-width: 992px) {
  .login-1 {
    padding-left: 450px;
  }
}
.login.login-1 .login-content.login-content-loading {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.login.login-1 .login-content.login-content-loading .login-form {
  max-height: 100vh;
}
/* end, login-1 page fixed login-aside */

/* starts, color-picker */
.color-picker .color {
  position: relative;
  z-index: 0;
  display: inline-block;
  margin-top: 6px;
}
.color-picker .color-box-value {
  margin-top: 6px;
}
.color-picker .color div.selected::before {
  position: absolute;
  top: 13.5px;
  left: 13px;
  color: white;
  font-size: 1.5em;
}
/* starts, color-picker */

.subscription-discount {
  color: #37db70;
  font-weight: 600;
}

.article-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.cropper-modal {
  background-color: #f1f1f1;
}

.kt-avatar__holder.bg-pos {
  background-position: 50% 50% !important;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 800px;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.shine-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.p-7 {
  padding: 1.75rem !important;
}

@media (min-width: 768px) {
  .pt-38,
  .py-38 {
    padding-top: 7rem !important;
  }
  .pb-38,
  .py-38 {
    padding-bottom: 7rem !important;
  }
}

.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}
.mt-8,
.my-8 {
  margin-top: 2rem !important;
}
.mt-25px {
  margin-top: 25px !important;
}
.c-separator hr {
  border-top: 3px dashed rgba(0, 0, 0, 0.1);
}

.alert-button button {
  margin-top: 8px;
  background: transparent;
  color: white;
  border: 1px solid white;
  padding: 6px 20px;
}

.alert-button button:hover {
  background-color: white;
  color: #ffb822;
}

/* .website-selector {
  width: 175px;
}

.website-selector-wide {
  width: 300px;
}

.website-selector .async-select-paginate__input {
  display: none !important;
}

.website-selector .async-select-paginate__value-container {
  max-height: 25px
} */

.rdw-link-modal-target-option {
  margin-bottom: 10px !important;
}

.rdw-link-modal {
  height: 230px !important;
  background: rgb(51, 51, 51) !important;
  color: white !important;
}

/* starts, aside menu */
@media (min-width: 1025px) {
  .kt-aside--fixed .kt-aside {
    overflow: auto;
  }
}
/* end, aside menu */

/* starts, react-draft-wysiwyg custom css */
.c-wysiwyg {
}
.c-wysiwyg .rdw-editor-toolbar {
  background-color: #f3f6f9;
  margin-bottom: 0;
  border-bottom: 1px solid #d6d9ea;
  padding: 0;
}
.c-wysiwyg .rdw-inline-wrapper,
.c-wysiwyg .rdw-link-wrapper {
  margin: 0;
}
.c-wysiwyg .rdw-editor-toolbar .rdw-option-wrapper {
  background-color: transparent;
  border: 0;
}
.c-wysiwyg .rdw-option-wrapper:hover,
.c-wysiwyg .rdw-option-wrapper:active {
  box-shadow: none !important;
  background: whitesmoke !important;
}
.c-wysiwyg .rdw-option-active{
  box-shadow: none !important;
  background: whitesmoke !important;
}
.c-wysiwyg .c-option {
  padding: 8px 26px;
  border-radius: 0;
  height: 32px;
  margin: 0 0 0 0;
  border-right: 1px solid #d6d9ea !important;
}
.c-wysiwyg .rdw-link-modal-buttonsection {
  flex-direction: row-reverse;
  display: flex;
  margin: 0 0 0 9px;
}
.c-wysiwyg .rdw-link-modal-buttonsection button {
  box-shadow: none;
  flex: 0 0 50%;
}
.c-wysiwyg .rdw-link-modal-buttonsection button:first-child {
  background-color: #333333;
  border-color: #333333;
  color: #ffffff;
}
.c-wysiwyg .rdw-link-modal-buttonsection button:first-child:hover {
  background-color: #111;
  border-color: #111;
}
.c-wysiwyg .rdw-link-modal {
  box-shadow: none;
  left: -1px;
}
.c-wysiwyg .rdw-link-modal input#linkTitle,
.c-wysiwyg .rdw-link-modal input#linkTarget {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.c-wysiwyg a {
  text-decoration: underline;
}
/* end, react-draft-wysiwyg custom css */

/* starts, domain settings */
ul.nameservers {
  list-style: none;
  padding: 0;
  margin-top: 16px;
}
ul.nameservers li {
  padding: 1px 0;
}
.nameserver {
  margin-bottom: 16px;
}
.nameserver .name {
  min-width: 200px;
}
.nameserver .btn-outline-dark:not(:hover) {
  color: #333333;
}
.nameserver.b-color {
  border-color: #adadad;
}
ul.nameservers,
.nameserver {
  border: 1px solid #ebedf2;
  padding: 12px;
  border-radius: 4px;
  background: #f2f3f8;
  color: #111;
  font-weight: 400;
  font-size: 14px;
}
/* end, domain settings */

/* starts, use existing domain */
.use-existing-domain .rounded {
  border-radius: 6px !important;
}
.use-existing-domain .shadow {
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}
.use-existing-domain .pricing-rates {
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #adb5bd !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  height: 100%;
}
.use-existing-domain .pricing-rates.business-rate:hover,
.use-existing-domain .pricing-rates.business-rate.active {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  z-index: 2;
  background: #333;
  border-color: #333333 !important;
  -webkit-box-shadow: 0 5px 13px rgba(51, 51, 51, 0.2) !important;
  box-shadow: 0 5px 13px rgba(51, 51, 51, 0.2) !important;
}
.use-existing-domain .pricing-rates.business-rate:hover > *,
.use-existing-domain .pricing-rates.business-rate.active > * {
  color: #fff;
}
.use-existing-domain .pricing-rates.starter-plan {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  z-index: 2;
  border-color: #333333 !important;
}
@media (max-width: 767px) {
  .use-existing-domain .pricing-rates.starter-plan {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.use-existing-domain .pricing-rates.best-plan:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.use-existing-domain .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.use-existing-domain .card .card-body {
  padding: 1.5rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
}
.use-existing-domain .list-unstyled li {
  position: relative;
  z-index: 0;
  padding: 0 0 0 23px;
}
/* end, use existing domain */

/* starts, img-v1 */
.img-v1 {
  width: 52px;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 3px;
  border-radius: 8px;
  margin: 0;
}
/* end, img-v1 */

/* starts, select website */
.select-website .website-selector {
  border-radius: 4px;
  min-width: 200px;
}
.select-website .selected-value {
  max-height: 30px;
  margin-top: 1px;
}
.select-website .selected-value .logo {
  height: 20px;
}
.select-website .selected-value .value {
  word-break: break-all;
}
.select-website .css-jjod5a-control {
  max-height: 44px;
}
.website-selector .async-select-paginate__input {
  position: absolute;
  top: 0;
  z-index: -1000;
}
/* end, select website */

/*.floating-toolbar {*/
/*  display: none;*/
/*}*/

.handle:hover {
}

[draggable] {
  user-select: none;
  -webkit-user-drag: element;
}

/* starts, Form Builder */
.form-builder {
  /* background-color: red; */
}
.form-builder .fields {
  /* background-color: black; */
}
.form-builder .fields .btn {
  background-color: #e1f0ff;
  user-select: none;
  padding: 8px;
  width: 100%;
  outline: none;
}
.form-builder .new-form {
  /* background-color: green; */
}
.form-item {
  position: relative;
  z-index: 0;
  border: 1px solid #fff;
  padding: 6px;
}
.form-item::before {
  position: absolute;
  width: 20px;
  top: -1px;
  bottom: -1px;
  left: -20px;
  background-color: #e1f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
}
.form-item.hover {
  border-color: #e1f0ff;
}
.form-item .toolbar {
  position: absolute;
  right: 0;
  top: -20px;
  background: #333;
  z-index: 1;
  border-radius: 6px;
}
.form-builder .itemdropzone {
  min-height: 10px;
}
.form-builder .itemdropzone:not(.sides) {
  margin: 6px 0 6px 0;
}
.form-builder .itemdropzone.sides {
  min-width: 20px;
  max-width: 20px;
}
.form-builder .itemdropzone.show {
  background-color: #e1f0ff;
}
.form-builder-buttons {
  width: 240px;
  height: 38px;
}
.form-builder-buttons.btn {
  line-height: 23px;
}
@media (max-width: 767px) {
  .form-builder-engagecard .bgi-no-mobile {
    background: none !important;
  }
}
.contact-items {
  background: #ebdff2 !important;
}
/* end, Form Builder */
/* Start, Kanban custom css */

.kanban-container {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.kanban-container .kanban-board {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.kanban-container .kanban-board span.bg-transparent {
  padding: 0;
  height: auto;
}

.kanban-v2 {
}

.kanban-v2 .kanban-container {
  overflow-y: auto;
}

.kanban-v2 .kanban-container {
  min-height: calc(100vh - 107px);
  padding: 0 25px;
}
.mobile-crm .kanban-v2 .kanban-container {
  min-height: calc(100vh - 27px);
}

.kanban-v2 .kanban-container .kanban-board {
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 250px !important;
}
.kanban-v2 .kanban-board header {
  padding-top: 11px;
  padding-bottom: 11px;
}
.kanban-v2 .kanban-board-header .btn {
  border-radius: 0.42rem;
}
.kanban-v2 .kanban-container .kanban-board .kanban-title-board .label {
  position: relative;
  z-index: 0;
  top: -2.5px;
  line-height: normal;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon {
  position: relative;
  z-index: 0;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon .bg-color {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.42rem;
  display: none;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon:not(:hover) .default {
  display: block;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon:hover .hover {
  display: block;
}
.kanban-v2 .kanban-container .kanban-board .kanban-drag {
  min-height: auto;
  padding: 10px 0 10px 10px;
  margin: 10px;
}
.kanban-v2 .kanban-container .kanban-board .kanban-drag .kanban-drag-list {
  overflow-y: auto;
  max-height: calc(100vh - 211px);
  padding: 0 10px 0 0;
}
.mobile-crm
  .kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list {
  max-height: calc(100vh - 138px);
}
.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-thumb {
  background: #e4e6ef;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-thumb:hover {
  background: #e4e6ef;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-thumb:active {
  background: #e4e6ef;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-track:hover {
  background: transparent;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-track:active {
  background: transparent;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-corner {
  background: transparent;
}

.kanban-v2 .kanban-container::-webkit-scrollbar {
  height: 10px;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-thumb {
  background: #d1d3e0;
  border: 0px none #ffffff;
  border-radius: 0.42rem !important;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-thumb:hover {
  background-color: #d1d3e0;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-thumb:active {
  background-color: #d1d3e0;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.42rem !important;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-track:hover {
  background: transparent;
  background-color: #f3f6f9;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-track:active {
  background: transparent;
  background-color: #f3f6f9;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-corner {
  background: transparent;
}
@media (max-width: 768px) {
  .kanban-container .kanban-board {
    margin-right: 1.25rem !important;
  }
}
.inline-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.kanban-list-idle {
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
  background: #f7f7f9;
}
.kanban-list-idle:not(.show-form):hover {
  background: #dedee0;
}
/* end, Kanban custom css */

/* starts, btn groups to set mobile buttons alignment */
.c-btn-group {
}
.c-btn-group .btn:not(:last-child) {
  margin-right: 1.5rem !important;
}
@media (max-width: 812px) {
  .c-btn-group .btn {
    display: block;
  }
  .c-btn-group .btn:not(:last-child) {
    margin-bottom: 1.5rem !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1024px) {
  .c-btn-group .btn {
    min-width: 188px;
  }
}
/* end, btn groups to set mobile buttons alignment */
.disabled-link {
  pointer-events: none;
  cursor: text;
}

/* starts, Kanban and Table tabs css */
.c-tabs .nav-pills {
  display: inline-flex;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 60px !important;
  margin-bottom: 26px;
  padding: 6px;
  font-weight: 600;
}
@media (min-width: 1025px) {
  .c-tabs .nav-pills {
    position: fixed;
    background-color: #eef0f8;
    top: 11px;
    z-index: 100;
    left: 50%;
  }
}
.c-tabs .nav-pills .nav-link.active,
.c-tabs .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #333333;
  border-radius: 48px !important;
}
.c-tabs .nav-link {
  padding: 6.5px 30px;
}
.c-tabs .nav-link:hover {
  color: #333333;
}
/* end, Kanban and Table tabs css */

/* starts, Material UI elements overwrites */
.MuiInputLabel-shrink,
.MuiFormLabel-filled {
  font-size: 16px !important;
}
.MuiOutlinedInput-notchedOutline legend {
  font-size: 12px !important;
}
/* end, Material UI elements overwrites */

/* starts, product variant */
.opacity-50 {
  opacity: 0.5 !important;
}

.p-20 {
  padding: 20px !important;
}

.icon-2x {
  font-size: 2rem !important;
}

.variant-image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.variant-image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem !important;
}
/* end, product variant */

/*** Chats ***/

.sender-info {
  color: #48465b;
  font-weight: 500;
  font-size: 1.1rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.message-date {
  color: #74788d;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 2.95px;
  font-family: Poppins, Helvetica, sans-serif;
}

.justify-text-end {
  justify-content: end;
}

.justify-text-start {
  justify-content: start;
}

.chat-link-content {
  color: #48465b;
}

.chat-link-content:hover {
  color: #74788d;
}

.kt-chat__icon .profile-icon {
  height: 20px;
}

.kt-message__input {
  width: 100%;
  padding: 6px;
  border: none;
  outline: none;
  border-radius: 0.25rem;
}

.kt-message__input::placeholder {
  color: #74788d;
}

.kt-editor__width {
  width: 80%;
}

.send-icon {
  border: none;
  outline: none;
  background: none;
  width: 60px;
}

.send-icon > img {
  width: 25px;
  margin-top: 0.3rem;
}

.chat-input {
  background-color: #f5f5f5;
}

.kt-chat .kt-chat__input .kt-chat__editor textarea {
  background-color: #ffffff !important;
}

.bg-whitesmoke {
  background: whitesmoke;
}

/*** Lazy Loading No Content ***/
.form-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
}

/*** Custom Scrollbar ***/

.custom-scrollbar {
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 5px;
  opacity: 1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #e0e2f0;
  border-radius: 5px;
  opacity: 1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover > {
  background: #e0e2f0;
  opacity: 1;
}

/*** Dropzone ***/

.dropzone-container {
  display: grid;
  justify-items: center;
}

.dzu-dropzone {
  overflow: hidden !important;
}

.font-fam {
  font-family: Poppins, Helvetica, "sans-serif";
}

.upload-file {
  position: absolute;
  top: 40%;
  left: 45%;
  z-index: 200;
}
.fa-cloud-upload-alt {
  color: white;
}

/*** Timeline ***/

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.25rem;
}

.timeline.timeline-6 {
  position: relative;
}

.timeline.timeline-6:before {
  content: "";
  position: absolute;
  left: 51px;
  width: 3px;
  top: 0;
  bottom: 0;
  background-color: #ebedf3;
}

.timeline.timeline-6 .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin-bottom: 1.7rem;
}

.timeline.timeline-6 .timeline-item .timeline-label {
  width: 50px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  color: #3f4254;
}

.timeline.timeline-6 .timeline-item .timeline-badge {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: white;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 1px;
  margin-left: -0.5rem;
  padding: 3px !important;
  border: 6px solid #ffffff !important;
}

.icon-xl {
  font-size: 1.75rem !important;
}

.timeline.timeline-6 .timeline-item .timeline-content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.text-size-lg {
  font-size: 15px;
}

.text-size-xl {
  font-size: 17px;
}

.text-align-center {
  text-align: center;
}

.font-weight-boldest {
  font-weight: 800;
}

.text-darker {
  color: #333333;
}

/*** Personal Web Designer ***/

.kt-image__section {
  display: grid;
  place-content: "center";
}

.kt-bg__image {
  width: 40%;
  margin: 0 auto;
}

.notification-message-container {
  background: #333 !important;
}

.notification-message {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.notification-message-head span {
  color: white;
}

.notification-message-body span {
  color: #e5e5e5;
}

/* Starts: Subscription Styling */
.subscription-overview {
  margin: 20px;
  margin-top: 30px;
}

.subscription-overview-current {
  padding: 10px;
  background: #fafafa;
  text-align: center;
}

.subscription-overview-current-title {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}
.subscription-overview-current-price {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

.card.package {
  overflow: hidden;
  cursor: pointer;
}

.package-active-label {
  position: absolute;
  top: 25px;
  left: -25px;
  width: 120px;
  transform: rotate(-45deg);
  background-color: #333;
  text-align: center;
  color: white;
}

.package-selected {
  background-color: #f5f5f5;
  border: solid 1px #999;
}
/* Ends: Subscription Styling */

/* Starts: Text Editor Styles */

.white-border {
  border: 1px solid white !important;
}

.white-top-border {
  border-top: 1px solid white !important;
}

.wrapper-styles {
  width: 107.5%;
  float: right;
  margin-right: -10px !important;
}

/* Ends: Text Editor Styles */



.m-option label {
    font-weight: 400;
    font-size: 1rem;
}

.m-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 1.4em;
    border-radius: 6px;
    border: 1px solid #ebedf2;
}

.m-option .m-option__label .m-option__head .m-option__title {
    color: #3f4047;
}

.m-option .m-option__label .m-option__head .m-option__title {
    font-size: 1.1rem;
}

.m-option .m-option__label .m-option__head .m-option__focus {
    color: #3f4047;
    font-size: 1.1rem;
    font-weight: 600;
}

.m-option .m-option__label .m-option__body {
    display: block;
    padding-top: 0.7rem;
    font-size: .85rem;
}

.border-day {
  padding: 20px;
  background-color: #FAFAFA;
  border-radius: 8px;
}

.operating-hours {
  margin-left: 3px;
  border: 1px solid #d7dbde;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.col-operating-hour {
  background-color: #f3f6f9;
}

.resources {
  padding: 20px;

}

.service-resource {
  margin-left: 3px;
  padding: 20px;
  border: 1px solid #d7dbde;
  background-color: #FAFAFA;
  border-radius: 4px;
}

.service-resource .async-select-paginate,
.service-resource .async-select-paginate > div:first-child {
  background-color: #FFF !important;
}

/** CALENDAR **/

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #3333333b !important;
  border: 1px solid #333 !important;
}

.rbc-event:focus {
  outline: none !important
}

.rbc-event-label {
  font-weight: 500;
  color: #333 !important;
}
.rbc-event-content {
  font-weight: 800;
  color: #333 !important;
}
.rbc-current-time-indicator {
  height: 2px !important;
  background-color: #FA6160 !important;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-header,
.rbc-label,
.rbc-toolbar-label,
.rbc-date-cell,
.rbc-agenda-empty,
.rbc-show-more,
.rbc-agenda-table {
  color: #333 !important;
}

.bookings-calendar .async-select-paginate {
  min-width: 200px;
}
