//page partnership

&.page {
    &.partnership {


        .login-aside {
            .aside-points {
                background-color: #b7b7b7;
                padding: 20px 16px 16px 20px;
                border-radius: 16px;
                text-align: left;
                .btm-text{
                    font-size: 13px;
                    color: white;
                    margin-top: 20px;
                }
            }
        }

        .login-content {
            .login-form {

                .form-heading {
                    font-size: 26px;
                    color: #212121;
                    font-weight: 600;
                    margin-bottom: 6.5px;
                }

                .form-title {
                    font-size: 17.55px;
                    font-weight: 500;
                    color: #b5b5c3;
                }

                .form-group {
                    label {
                        span {
                            &.sub-title {
                                color: #333333;
                            }
                        }

                        &.c-tooltip {
                            span {

                                &.title,
                                &.icon-help {
                                    display: inline;
                                }

                                &.icon-help {
                                    font-size: 21px;
                                    opacity: 0.5;
                                    position: relative;
                                    z-index: 0;
                                    left: 8px;
                                    top: 5px;
                                }
                            }
                        }
                    }

                    .note-email {
                        font-weight: 600;
                        color: #fa6160;
                        text-align: justify;
                        padding: 0 6px;
                    }
                }

                .form-card {
                    background-color: rgba($color: #fff1e5, $alpha: 0.5);
                    padding: 20px 16px 16px 20px;
                    border-radius: 16px;

                    .form-control {
                        &.form-control-solid {
                            background-color: white !important;

                            &:focus {
                                background-color: rgba($color: #ebfcf5, $alpha: 0.6) !important;
                                border-color: rgba($color: #37DB70, $alpha: 0.5) !important;
                            }
                        }
                    }

                    textarea {
                        max-height: 70px;
                    }
                }

                .slidecontainer {
                    position: relative;
                    z-index: 0;
                    padding: 0;

                    .row {
                        align-items: center;

                        label {
                            margin: 0;
                        }
                    }

                    input[type] {
                        -webkit-appearance: none;
                        background: #fcfcfc;
                        padding: .46428571em;
                        border-radius: 6px;
                        border: 1px solid #ececec;
                        font-size: 16px;
                        line-height: 16px;
                        text-align: center;
                        color: #181C32;
                        font-weight: 600;
                        max-width: 100%;
                        width: 100%;
                        color: $lyttleInk-green;
                    }

                    input[type='range'] {
                        z-index: 1;
                        position: relative;
                        background-color: transparent;
                        margin-bottom: 16px;

                        @include media-breakpoint-md {
                            margin-bottom: 0;
                        }

                        border: 0;
                        background-color: transparent;
                        padding: 0;

                        //-webkit-appearance: none;
                        appearance: none;
                        cursor: pointer;
    
                        &::-webkit-slider-runnable-track {
                            //background: #e8ebf1;
                            border-radius: 15px;
                            height: 24px;
                        }
    
                        &::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            /* Override default look */
                            appearance: none;
                            background-color: #2d2d2d;
                            height: 36px;
                            width: 36px;
                            border-radius: 50%;
                            margin-top: -6px;
                        }

                        &[disabled]{
                           cursor: wait;
                        }
                    }

                    .r-field {
                        position: relative;
                        z-index: 0;

                        .progress {
                            background-color: transparent;
                            height: auto;
                            position: absolute;
                            right: 20px;
                            bottom: 8.5px;
                            left: 20px;
                            top: 9px;
                            z-index: 0;

                            .bar {
                                background: #e8ebf1;
                                border-radius: 15px;
                                height: 24px;
                                width: 100%;
                            }

                            .r-val {
                                width: 11%;
                                background-color: #19E68A;
                                transition: all 0.1s ease;
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 0;
                                height: 24px;
                                border-radius: 6px 0 0 6px;
                            }
                        }
                    }

                }

                

                .c-separator {
                    margin-bottom: 32px !important;
                }

            }
        }
    }
}
